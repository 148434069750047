


































import {
  Component, Vue, Prop, Emit,
} from 'vue-property-decorator';
// eslint-disable-next-line import/no-cycle
import PromotionService from '@/services/PromotionService';

const promotionService = new PromotionService();

@Component
export default class PromotionPage extends Vue {
  public promotions: any = [];

  async created() {
    const { data } = await promotionService.getAvailablePromotions();
    this.promotions = [...data];
  }
}
